.primaryBox {
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
  padding: 3rem 7rem;
}

.sectionHeading {
  font-size: 56px;
  color: #560323;
  font-weight: 500;
}

.blogsContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 2rem;
}

.blog {
  background-color: #60092b;
  color: #fff;
  width: 18rem;
  height: 30rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  justify-content: space-between;
  padding: 2rem;
  border-radius: 5px;
}

.blogImage {
  width: 100%;
  min-height: 10rem;
  object-fit: fill;
  border-radius: 5px;
}

.blogName {
  max-height: 4rem;
  overflow: hidden;
  font-size: 1.2rem;
  font-weight: 500;
  text-align: center;
  text-transform: uppercase;
}

.blogPrev {
  width: 100%;
  max-height: 8.2rem;
  overflow: hidden;
  font-size: 0.8rem;
  text-align: justify;
}

.readMoreBtn {
  width: 100%;
  text-align: center;
  padding: 0.4rem 0;
  background-color: #691434;
  border-radius: 8px;
  font-size: 1.1rem;
  cursor: pointer;
  transition-property: color, background-color, border-color,
    text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter,
    backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.readMoreBtn:hover,
.readMoreBtn:active {
  background-color: #711739;
}
