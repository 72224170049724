.box {
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding: 30px 15%;
}

.pageHeading {
  text-transform: uppercase;
  font-size: 3rem;
  font-weight: 600;
  color: #7b1a3f;
}

.applyBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 15px;
}

.desc {
  font-size: 17px;
  letter-spacing: 1.5px;
  font-weight: 400;
  text-align: justify;
  width: 100%;
}

.desc b {
  font-size: 19px;
  margin-top: 1.5rem;
}

.applyForm {
  padding: 0 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 15px;
}

.applyForm span {
  font-weight: 600;
  font-size: 30px;
}

.inputGroup {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: flex-start;
  justify-content: center;
}

.inputGroup input,
.inputGroup select {
  width: 100%;
  padding: 10px 10px;
  outline: none;
  border: 1px solid rgb(169, 166, 166);
  border-radius: 5px;
  font-size: 16px;
}

.inputGroup input:focus,
.inputGroup select:focus {
  outline: 2px solid rgb(28, 94, 185);
}

.inputGroup label {
  font-weight: 400;
  font-size: 18px;
}

.submitBtn {
  width: 100%;
  text-align: center;
  padding: 10px 0px;
  font-size: 20px;
  font-weight: 500;
  background-color: #6b1738;
  color: white;
  border-radius: 5px;
  cursor: pointer;
}

.submitBtn:hover {
  background-color: #7c2246;
}

.submitBtn:active {
  background-color: #601332;
}

.openPosBox {
  padding: 3rem 1rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 10px;
}

.position {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 5px;
}

.position h2 {
  width: 100%;
  text-align: center;
  color: #7c2246;
  font-size: 3.5rem;
  font-weight: 600;
}

.posSection span {
  font-weight: 600;
  font-size: 25px;
}

.posSection p {
  font-size: 20px;
}

.posSection ol li {
  font-size: 20px;
}

/* media queries */
@media (min-width: 700px) {
  .applyBox {
    flex-direction: row;
    align-items: flex-start;
  }
  .desc {
    margin-top: 4rem;
    width: 50%;
  }
  .applyForm {
    width: 50%;
  }
  .openPosBox {
    padding: 5rem 10rem;
  }
}
