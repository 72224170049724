.engsth {
  color: #560323;
  text-align: center;
  padding: 1rem;
  font-size: 3rem;
}

.engstcard-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.engstcard {
  width: 100%;
  background-color: #fff;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
  border-radius: 25px;
  overflow: hidden;
  padding: 2rem;
}

.engstcard-text {
  padding: 20px;
}

.engstdata {
  font-size: 20px;
  line-height: 1.5;
  padding: 1rem;
  background-color: #1f453d;
  color: #ffffff;
}


@media (max-width: 767px) {
  .engsth {
    font-size: 2rem;
  }
  
  .engstcard {
    padding: 1.5rem;
  }
  
  .engstcard-text {
    padding: 10px;
  }
  
  .engstdata {
    font-size: 16px;
    padding: 0.5rem;
  }
}

