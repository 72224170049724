.navbar {
  background-image: linear-gradient(to right, #560323, #560323);
  padding: 10px 0;
}

.nav-link {
  color: #ffffff;
  font-weight: bold;
}

.nav-link:hover {
  color: #f4a0a0;
}

.navbar-brand {
  margin-right: 20px;
}

.navbar-toggler {
  margin-left: auto;
}

.nav-itembtn {
  background-color: #ffffff;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  padding: 4px 14px;
  font-size: 16px;
  cursor: pointer;
  margin-left: 10px;
}

.nav-btnlink {
  color: #560323;
  font-weight: bold;
  align-items: center;
}

.nav-btnlink:hover {
  color: #ffffff;
}

.nav-itembtn:hover {
  background-color: #57086a;
  color: #ffffff;
}

@media (max-width: 767px) {
  .navbar {
    padding: 7px 0;
  }

  .navbar-nav .nav-item {
    margin: 5px;
  }
  /* .navbar-toggler {
    margin-right: -15px;
  } */
}
