.logo {
  width: 5.10rem;
}

.nav-link > li > a {
  color:black !important;
}

.navbar-nav > li > a.active, .navbar-nav > li > a:focus {
  border-top: 0.1875rem solid var(--primary-orange);
}