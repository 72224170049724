.medisth {
  color: #560323;
  text-align: center;
  padding: 1rem 1rem 1rem 1rem;
  font-size: 3rem;
}
.medistcard-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.medistcard {
  width: 100%;
  background-color: #fff;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
  border-radius: 25px;
  overflow: hidden;
  padding: 2rem 2rem 2rem 2rem;
}

.medistcard-text {
  padding: 20px;
}

.medistdata {
  font-size: 20px;
  line-height: 1.5;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 3rem;
  padding-right: 3rem;
  background-color: #fa9292;
  color: #410224;
}
