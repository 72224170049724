.card-body-cont {
  margin-bottom: 50px;
}

.card-body-cont {
  margin-left: 15rem;
  margin-right: 15rem;
  margin-bottom: 88px;
}

.pjrow-cont {
  margin-left: 6px;
}

.desc-cont {
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
}

/* Responsive Styles */
@media screen and (max-width: 768px) {
  .card-body-cont {
    margin-left: 2rem;
    margin-right: 2rem;
    margin-bottom: 50px;
  }

  .pjrow-cont {
    margin-left: 0;
  }

  .desc-cont {
    padding: 0.5rem;
  }
}
