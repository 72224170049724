.donate-page {
  max-width: 900px;
  margin: 0 auto;
  padding: 20px;
}

.donate-info {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}

.donate-methods {
  flex: 1 1 300px;
  margin-right: 20px;
}

.bank-details {
  flex: 1 1 300px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border: 2px solid #560323;
  background-color: #560323;
  padding: 20px;
  color: #ffffff;
  margin-top: 20px;
}

.logo-container {
  text-align: center;
  margin-bottom: 20px;
}

.logo {
  max-width: 100px;
}

.bank-details-content {
  text-align: center;
}

.bank-details p {
  margin: 20px 0;
}

.note {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #f8f8f8;
  border-top: 1px solid #e6e6e6;
  padding: 10px;
  text-align: center;
}

.note p {
  margin: 0;
  color: #560323;
  font-weight: bold;
}
