.slider-container {
  margin-top: 1rem;
  position: relative;
  overflow: hidden;
  height: 4rem;
}

.slider-text {
  position: absolute;
  left: 100%;
  white-space: nowrap;
  font-size: 2.5rem;
  color: #560323;
  animation: slide 20s linear infinite;
}

@keyframes slide {
  from {
    left: 100%;
  }
  to {
    left: -100%;
  }
}
