.p__box {
  padding: 2rem 7rem;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}

.p__sec_heading {
  text-transform: uppercase;
  font-size: 3.5rem;
  font-weight: 600;
  color: #560323;
}

.p__quote {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
}

.p__subheading {
  text-align: center;
  font-size: 1.5rem;
  font-weight: 500;
}

.p__sec_subheading {
  text-transform: uppercase;
  font-size: 2rem;
  font-weight: 600;
  color: #560323;
}

.p__cards {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 1rem;
  width: 100%;
  flex-wrap: wrap;
}

.part__box {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  /* width: 15rem; */
}

.part__imgBox {
  width: 150px;
  height: 5rem;
  padding: 0 10px;
}
.part__imgBox img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.part__heading {
  text-transform: uppercase;
  font-size: 1.5rem;
  font-weight: 800;
}

.part__textBox {
  width: 17rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 1rem;
}

#part__heading_0 {
  color: #7db9ae;
}

#part__heading_1 {
  color: #feb14a;
}

#part__heading_2 {
  color: #d26428;
}

.partner__card {
  width: 200px;
  height: 7rem;
}

.partner__card img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
