.ygh{
    color: #560323;
    text-align: center;
    padding: 1rem 1rem 1rem 1rem;
    font-size: 3rem;
   }
   .ygcard-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  
  .ygcard {
    width: calc(50% - 10px);
    background-color: #fff;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    overflow: hidden;
  }
  
  .ygcard-img {
    width: 100%;
    height: auto;
  }
  
  .ygcard-text {
    padding: 20px;
  }
  
  
 .ygdata {
    font-size: 20px;
    line-height: 1.5;
    padding: 1rem 1rem 1rem 1rem;
    background-color: #e68a00;
    color: #ffffff;
  }
  