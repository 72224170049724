.review-slider {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  
  .review-slider__card {
    width: 100%;
    max-width: 500px;
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
  }
  
  .review-slider__card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .review-slider__card-rating {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .review-slider__card-rating i {
    color: #ffc107;
  }
  
  .review-slider__controls {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .review-slider__button {
    background-color: #fff;
    color: #ffffff;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    margin: 0 10px;
  }
  
  .review-slider__button:hover {
    color: #ffc107;
  }
  
    
  .mb3{
    color: #ffffff !important;
  }
  
  .col-lg-8.text-muted {
    color: #ffffff !important;
  }

  .text-center{
    padding-top: 0.5rem;
  }
  .my-5{
    color: #ffffff !important;
    background-color: #560323;
  }
  .text-muted{
    color: #ffffff !important;
    padding-top: 0.5rem;
  }