.containerIecabout {
  padding-bottom: 20px;
}

.full-width-img {
  width: 100%;
  height: auto;
}

.cardiec {
  font-size: 1.5rem;
  width: 70%;
  margin: 0 auto;
  color: #ffffff;
  background-color: #013245;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  margin-top: 2rem;
}

.paragraph {
  margin: 0;
}
