.wbcards-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem 2rem 2rem 2rem;
  }
  
  .wbrow {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 1000px;
  }
  
  .wbcard {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 300px;
    height: 390px;
    margin: 20px;
    border-radius: 10px;
    border-color: #560323;
    border-style: groove;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease-in-out, height 0.3s ease-in-out;
  }
  
  .wbcard:hover {
    transform: scale(1.2);
    height: 330px;
  }
  
  .wbcard-image {
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    margin-bottom: 0.5rem;
  }
  
  .wbcard-title {
    margin: 16px;
    text-align: center;
    color: #560323;
  }
  
  .wbcard-button {
    padding: 10px;
    border: none;
    border-radius: 5px;
    background-color: #560323;
    color: white;
    cursor: pointer;
  }
  
  /* Responsive Styles */
  @media (max-width: 768px) {
    .wbrow {
      flex-direction: column;
      align-items: center;
    }
    
    .wbcard {
      width: 80%;
      height: auto;
      margin: 10px 0;
    }
    
    .wbcard:hover {
      transform: none;
      height: auto;
    }
  }
  