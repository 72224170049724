.wrapperg {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50vh;
}

.sliderg {
  height: 210px;
  margin: auto;
  overflow: hidden;
  position: relative;
  width: 90%;
  /* border: 2px solid #100202; */
  border: 2px solid #560323;
  border-radius: 10px;
}

.sliderg .slide-trackg {
  -webkit-animation: scroll 30s linear infinite;
  animation: scroll 12s linear infinite;
  display: flex;
  width: calc(400px * 10);
  height: 300px;
}

.sliderg .slideg {
  height: 200px;
  width: 450px;
}

.sliderg .slideg img {
  margin-top: 3px;
  width: 90%;
  height: 200px;
  border-radius: 10px;
}

@-webkit-keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-250px * 6));
  }
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-250px * 6));
  }
}
