.mrccards-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 2rem 2rem 2rem;
}

.mrcrow {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1290px;
}

.mrccard {
  padding: 1rem 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 390px;
  height: 540px;
  margin: 20px;
  border-radius: 8px;
  border-color: #560323;
  border-style: groove;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease-in-out, height 0.3s ease-in-out;
}
.mrccard-des {
  margin: 0;
  text-align: justify;
  font-size: 15px;
}

.mrccard:hover {
  transform: scale(1.1);
  height: 550px;
}

.mrccard-image {
  width: 100%;
  height: 400px;
  object-fit: cover;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  margin-bottom: 0.5rem;
}

.mrccard-title {
  font-size: 1.5rem;
  margin: 16px 0;
  text-align: center;
  color: #560323;
}

.mrccard-button {
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: #560323;
  color: white;
  cursor: pointer;
  margin-bottom: 1rem;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .mrcrow {
    flex-direction: column;
    align-items: center;
  }

  .mrccard {
    width: 80%;
    height: auto;
    margin: 10px 0;
  }

  .mrccard:hover {
    transform: none;
    height: auto;
  }
}
