.document-table {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.table-heading {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}

table {
  border-collapse: collapse;
  width: 100%;
  max-width: 800px;
  border: 1px solid black; /* added border */
  margin: 1rem 1rem 1rem 1rem;
}

.document-title-header {
  width: 70%;
}

.download-header {
  width: 30%;
  text-align: center;
}

.document-row {
  border-bottom: 1px solid black;
}

.document-title {
  padding: 10px;
  text-align: left; /* added text-align */
}
.document-download-cell {
  text-align: center;
  padding: 10px;
}

.document-download-button {
  background-color: #560323;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
  font-size: 14px;
}
th,
td {
  text-align: center; /* added text-align */
  padding: 10px;
}

th.document-title-header {
  width: 70%;
}

th.download-header,
td.document-download-cell {
  width: 30%;
}
thead {
  border-bottom: 1px solid black; /* added border */
}

tr.document-row:first-child {
  border-top: 1px solid black; /* added border */
}
