.container-fluid {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.card-body {
  color: white;
}

.cardabt {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  text-align: center;
  border: 5px solid #560323;
  width: 50rem;
  margin-top: 55px !important;
}

.containera {
  /* background-color: red; */
  border: 5px solid #560323;
}

.card-title {
  text-align: center !important;
}

.cardabt-image {
  display: block;
  margin: 0 auto;
  border-radius: 50%;
  height: 200px;
  width: 200px;
  object-fit: cover;
}

.heading {
  color: white;
}

.cardabt-text {
  font-size: 18px;
  color: #560323;
  text-align: justify;
  margin: 0 auto;
  max-width: 80%;
}

.cardabt-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 130vh;
}

.card-text-aims {
  color: #560323;
}

.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  /* justify-content: space-evenly; */
  padding: 2em 1em;
  text-align: center;
  color: white;
  /* background-color: #560323; */
}

.column {
  width: 100%;
  padding: 0.5em 0;
}
h1 {
  width: 100%;
  text-align: center;
  font-size: 3.5em;
  color: #1f003b;
}
.card {
  /* box-shadow: 0 0 2.4em rgba(25, 0, 58, 0.1);
  padding: 3.5em 1em;
  border-radius: 0.6em;
  color: #ffffff;
  cursor: pointer;
  transition: 0.3s;
  background-color: #560323; */
  min-height: 500px;
  flex-grow: 1;
  width: fit-content;
  /* border-radius: 0.6em; */
  color: #ffffff;
  cursor: pointer;
  transition: 0.3s;
  background-color: #560323;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
}


.our-team-container .card .team-img-container {
  margin-bottom: 30px;
  border-radius: 100%;
  min-height: 14rem;
}
.our-team-container .card img {
  width: 12rem;
  border-radius: 100%;
}


.our-work-container .card img.work-img {
  width: 12rem;
  position: absolute;
  bottom: 20px;
  right: 20px;
}


.card h3 {
  font-weight: 500;
}
.card p {
  font-weight: 300;
  text-transform: uppercase;
  letter-spacing: 2px;
}
.icons {
  width: 50%;
  min-width: 180px;
  margin: auto;
  display: flex;
  justify-content: space-between;
}
.card a {
  text-decoration: none;
  color: inherit;
  font-size: 1.4em;
}
.card:hover {
  background: #7d0c37;
}

.card:hover .img-container {
  transform: scale(1.15);
}


@media screen and (max-width: 768px) {
  .cardabt {
  width: 90%;
  margin-top: 20px !important;
  }
  
  .cardabt-image {
  height: 150px;
  width: 150px;
  }
  
  .cardabt-text {
    font-size: 16px;
    max-width: 90%;
    margin-top: 10px;
    word-spacing: 1px;
  }
  
  .cardabt-container {
  height: auto;
  }


 
}


  