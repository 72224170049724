.awcards-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 60vh;
}

.awrow {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 482px;
}

.awcard {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 340px;
  height: 200px;
  margin: 20px;
  border-radius: 10px;
  border-color: #560323;
  border-style: groove;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease-in-out, height 0.3s ease-in-out;
}

.awcard:hover {
  transform: scale(1.1);
  height: 210px;
}

.awcard-title {
  margin: 16px;
  margin-bottom: 1rem;
  text-align: center;
  color: #560323;
  font-size: 1.3rem;
  font-weight: bold;
}

.awcard-button {
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: #560323;
  color: white;
  cursor: pointer;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .awrow {
    flex-direction: column;
    align-items: center;
  }

  .awcard {
    width: 80%;
    height: auto;
    margin: 10px 0;
  }

  .awcard:hover {
    transform: none;
    height: auto;
  }
}
