.card-cont {
  width: 100vw;
  background-color: white;
}
.card-body-cont {
  color: black;
  margin-bottom: 15px;
  margin-top: 50px;
}

.map {
  margin-bottom: 50px;
  margin-top: 25px;
  border: black;
}
.card-title-cont {
  margin-top: 20px;
  align-items: center;
  text-align: center;
  font-size: 3.5em;
  color: #1f003b;
}
