.anncontainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  padding: 5rem;
  gap: 10px;
}

.annimg {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain; /* or object-fit: cover; */
}
