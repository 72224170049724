.table-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto;
  padding: 2rem 1rem;
}

.table-title {
  padding: 1rem;
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 20px;
  margin-top: 1rem;
  text-align: center;
  color: #035a64;
}

.table {
  width: 100%;
  border-collapse: collapse;
  font-size: 16px;
  text-align: center;
  margin: 0 auto;
  max-width: 800px;
}

.table th,
.table td {
  padding: 10px;
  border: 1px solid #ddd;
}

.table th {
  background-color: #d5a0ba;
  font-weight: bold;
}

.table tr:nth-child(even) {
  background-color: #ddb7ca;
}

.table tr:hover {
  background-color: #e0a2a2;
}

@media only screen and (max-width: 600px) {
  .table {
    font-size: 14px;
  }
}
