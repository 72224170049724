.desc-cont {
  color: black;
}
.card-body-cont {
  color: black;
  margin-left: 15rem;
  margin-right: 15rem;
}

.pjcards-container-cont {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 2rem 2rem 2rem;
}

.pjrow-cont {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1000px;
}

.pjcard-cont {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 350px;
  height: 450px;
  margin: 20px;
  border-radius: 10px;
  border-color: #560323;
  border-style: groove;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease-in-out, height 0.3s ease-in-out;
}

.pjcard-image-cont {
  width: 100%;
  height: 288px;
  object-fit: fill;
  margin-bottom: 0.5rem;
}

.pjcard-title-cont {
  margin: 16px;
  text-align: center;
  color: #560323;
}

.pjcard-button-cont {
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: #560323;
  color: white !important;
  cursor: pointer;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .pjrow-cont {
    flex-direction: column;
    align-items: center;
  }

  .pjcard-cont {
    width: 80%;
    height: auto;
    margin: 10px 0;
  }

  .pjcard-cont:hover {
    transform: none;
    height: auto;
  }
}
