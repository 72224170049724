.yg1h {
  color: #560323;
  text-align: center;
  padding: 1rem 1rem 1rem 1rem;
  font-size: 3rem;
}
.yg1card-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.yg1card {
  width: calc(50% - 10px);
  background-color: #fff;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  overflow: hidden;
}

.yg1card-img {
  width: 100%;
  height: auto;
}

.yg1card-text {
  padding: 20px;
}

.yg1data {
  font-size: 20px;
  line-height: 1.5;
  padding: 1rem 1rem 1rem 1rem;
  background-color: #e68a00;
  color: #ffffff;
}
